
import * as upfanRuntime$gos5reZyul from '/workspace/app/providers/upfan.ts'
import * as ipxRuntime$aKhvuDNnhl from '/workspace/node_modules/.pnpm/@nuxt+image@1.8.0_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__ioredis@5.4.1_magicast@_c7pzjuzodlhqsyhydpjekhndpi/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['upfan']: { provider: upfanRuntime$gos5reZyul, defaults: {"baseURL":"https://upfan-bucket.fra1.cdn.digitaloceanspaces.com"} },
  ['ipx']: { provider: ipxRuntime$aKhvuDNnhl, defaults: {} }
}
        