import type { UseSeoMetaInput } from '@unhead/vue'

export function useIonMeta(seoInput: UseSeoMetaInput) {
  useServerSeoMeta(seoInput)
  useSeoMeta(seoInput)

  onIonViewWillEnter(() => {
    useSeoMeta(seoInput)
  })
}
