<template>
  <ion-header
    class="z-10 shadow-xl"
  >
    <ion-toolbar>
      <ion-row class="py-4">
        <ion-col>
          <UContainer>
            <div class="flex justify-between">
              <div class="flex">
                <div class="flex shrink-0 items-center">
                  <ULink
                    :to="localePath('index')"
                    @click.prevent="onMobileNavLinkClick($event, localePath('index'))"
                  >
                    <NuxtImg
                      class="block h-10"
                      src="/upfan.svg"
                      alt="Upfan"
                    />
                  </ULink>
                </div>
                <div class="hidden items-center md:ml-6 md:flex md:space-x-8">
                  <ULink
                    v-for="link in links"
                    :key="localePath(link.to)"
                    class="inline-flex items-center font-medium"
                    :to="localePath(link.to)"
                    :active="route.path === localePath(link.to)"
                    @click.prevent="onMobileNavLinkClick($event, localePath(link.to))"
                  >
                    {{ link.title }}
                  </ULink>
                </div>
              </div>
              <div class="ml-auto">
                <div class="block md:hidden">
                  <USlideover
                    side="top"
                    :close="{ size: 'xl' }"
                    :ui="{ title: 'inline-block', close: 'static cursor-pointer', header: 'flex justify-between', body: 'px-0 sm:px-0' }"
                  >
                    <template #default>
                      <UButton
                        icon="i-heroicons-bars-3"
                        size="xl"
                        square
                      />
                    </template>
                    <template #title>
                      <ULink
                        :to="localePath('index')"
                        class="inline"
                        @click.prevent="onMobileNavLinkClick($event, localePath('index'))"
                      >
                        <NuxtImg
                          class="block h-10"
                          src="/upfan.svg"
                          alt="Upfan"
                        />
                      </ULink>
                    </template>
                    <template #body>
                      <div>
                        <div
                          v-for="link in links"
                          :key="link.to"
                          class="block text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-900"
                        >
                          <ULink
                            class="block size-full px-4 py-2 font-medium sm:px-6 lg:px-8"
                            :to="localePath(link.to)"
                            :active="route.path === localePath(link.to)"
                            @click.prevent="onMobileNavLinkClick($event, localePath(link.to))"
                          >
                            {{ link.title }}
                          </ULink>
                        </div>
                        <div class="mt-4 px-4 sm:px-6 lg:px-8">
                          <div
                            v-if="authStore.isSignedIn"
                          >
                            <UButton
                              size="xl"
                              block
                              :to="localePath('index-profile')"
                              :ui="{ base: 'justify-center' }"
                              @click.prevent="onMobileNavLinkClick($event, localePath('index-profile'))"
                            >
                              {{ t('header.profile') }}
                            </UButton>
                          </div>
                          <div v-else>
                            <UButton
                              size="xl"
                              :to="localePath('index-auth-signin')"
                              variant="link"
                              block
                              :ui="{ base: 'justify-center' }"
                              @click.prevent="onMobileNavLinkClick($event, localePath('index-auth-signin'))"
                            >
                              {{ t('header.sign-in') }}
                            </UButton>
                            <UButton
                              size="xl"
                              :to="localePath('index-auth-signup')"
                              block
                              :ui="{ base: 'justify-center' }"
                              @click.prevent="onMobileNavLinkClick($event, localePath('index-auth-signup'))"
                            >
                              {{ t('header.create-account') }}
                            </UButton>
                          </div>
                        </div>
                      </div>
                    </template>
                  </USlideover>
                </div>
                <div class="hidden md:block">
                  <div v-if="authStore.isSignedIn">
                    <UButton
                      size="xl"
                      :to="localePath('index-profile')"
                      @click.prevent="onMobileNavLinkClick($event, localePath('index-profile'))"
                    >
                      {{ t('header.profile') }}
                    </UButton>
                  </div>
                  <div v-else>
                    <UButton
                      size="xl"
                      :to="localePath('index-auth-signin')"
                      variant="link"
                      @click.prevent="onMobileNavLinkClick($event, localePath('index-auth-signin'))"
                    >
                      {{ t('header.sign-in') }}
                    </UButton>
                    <UButton
                      size="xl"
                      :to="localePath('index-auth-signup')"
                      @click.prevent="onMobileNavLinkClick($event, localePath('index-auth-signup'))"
                    >
                      {{ t('header.create-account') }}
                    </UButton>
                  </div>
                </div>
              </div>
            </div>
          </UContainer>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()
const ionRouter = useIonRouter()

const localePath = useLocalePath()
const { t } = useI18n()

const authStore = useAuthStore()

const isMobileMenuOpen = ref(false)

const links = computed(() => [
  { title: t('header.home'), to: 'index' },
  { title: t('header.market'), to: 'index-events' },
  { title: t('header.tickets'), to: 'index-tickets' },
  { title: t('header.about'), to: 'about' },
])

const onMobileNavLinkClick = async (event: Event, link: string) => {
  await ionRouter.navigate(link, 'none', 'push')
  isMobileMenuOpen.value = false
}
</script>
